/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 29 juin 2017, 14:16:31
    Author     : jeacharr
*/


$black: #000;
$grayD: #ddd;
$white: #fff;
$white2: #e2e2e2;
$lightgrey: #F6F6F6;
$lightgrey2: #d1cdd1;
$darkGrey: #666666;
$blue: #0f5e96;
$blue1: #09365c;
$blue2: #004179;
$blue3: #203864;
$blue4: #236B9F;
$blue5: #428bca;
$blue6: #3E476D;
$blue7: #081A67;
$blue8: #11538B;
$red: #e10f21;
$redE: #EB212E;
$red_dark: #d80000;
$red_very_dark: #a80000;
$vividRed: #ff0018;
$orange: #ffba00;
$orange2: #ff8e01;
$orange3: #FD672B;
$orange4: #FF7200;
$greyB2: #b2b2b2;
$greyE0: #e0e0e0;
$greyE6: #e6e6e6;
$greyA2: #a2a2a2;
$greyF3: #f3f3f3;
$greyC: #ccc;
$greyCB: #cbcbcb;
$grey56: #565656;
$grey80: #808080;
$greybd: #bdbdbd;
$grey75: #757575;
$greyC0: #C0C0C0;
$greyBC: #777777;
$grayd8: #d8d8d8;
$grayd10: #a0a0a0;
$green: #61c320;
$greyLight: #f7f7f7;
$greyD3: #D3D3D3;
$grey4: #555555;
$greyS: #444;
$greyB1: #777;
$greyE62: #6E6E6E;
$gainsboro: #dcdcdc;
$green: #61c320;
$green0: #009B00;
$green03: #039b1f;
$gray: #6a6a6a;
$bleuLight: #4286C3;
$redLight: #ed2746;
$greenLight: #0F9D00;
$redDark: #ff0000;
$successGreen: #155724;
$backgroundSucess: #d4edda;
$borderSuccess: #c3e6cb;
$redAlertError: #721c24;
$backgroundAlert: #f8d7da;
$borderAlert: #f5c6cb;
$borderBlue: #045C9D;
$darkBlue: #166299;
$redDark2: #D01618;
$shadowBlack: rgba(0, 0, 0, 0.375);
$yellow: #FFB901;
$cyberYellow: #ffd200;
$gray3: #333333;
$grayF2: #f2f2f2;
$gray22: #222;
$greenLevel1: #059740;
$greenLevel2: #52af31;
$yellowLevel1: #c8d501;
$yellowLevel2: #ffed00;
$yellowLevel3: #fbbc02;
$orangeLevel5: #ed6606;
$redLevel1: #e40713;
$backgroundWarning: #ffba00;
$warningOrange: #7f5e04;
$blue9: #0f5e9b;
$grayColor: #282727;
$lightWarningOrange:rgb(160, 104, 0)