@import "assets/styles/scss/vendor/colors";
.header-section {
  h1 {
    color: $black;
  }
}

.content-section {
  h2 {
    color: $greyBC;
    margin: 40px 0;
  }

  h3 {
    color: $greyBC;
    margin: 20px 0;
  }

  p {
    margin: 10px 0;
  }

  ul {
    list-style: circle;
  }
}
